<template>
    <h2 class="h3 mb-8">Login</h2>

    <form
        class="block"
        @submit.prevent="onSubmit"
    >
        <form-row v-if="passwordSet">
            <form-field>
                <alert color="success">Your password has been set. You can now login.</alert>
            </form-field>
        </form-row>

        <form-row>
            <form-input
                id="email"
                v-model="email"
                label="Email"
                type="email"
                autocomplete="email"
                required
                autofocus
                :icon="['fad', 'envelope']"
                :errors="errors.email"
                v-bind="emailAttrs"
            />
        </form-row>

        <form-row>
            <form-input
                id="password"
                v-model="password"
                label="Password"
                type="password"
                autocomplete="current-password"
                required
                :icon="['fad', 'key']"
                :errors="errors.password"
                v-bind="passwordAttrs"
            />
        </form-row>

        <div>
            <div class="mb-2">
                <nuxt-link
                    to="/login/reset-password"
                    class="text-sm underline"
                >
                    I've forgotten my password.
                </nuxt-link>
            </div>

            <div class="flex justify-end">
                <styled-button
                    type="submit"
                    :icon="['fad', 'right-to-bracket']"
                    :loading="loading"
                    :disabled="!meta.valid"
                >
                    Login
                </styled-button>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faRightToBracket, faEnvelope, faKey } from '@fortawesome/pro-duotone-svg-icons';

    import { SignInError } from '~/modules/auth/runtime/composables/useAuth';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/yup';
    import * as yup from 'yup';

    library.add(faRightToBracket, faEnvelope, faKey);

    definePageMeta({
        layout: 'login',
        anonymous: true,
    });

    const auth = useAuth();
    const route = useRoute();

    const { errors, meta, defineField, handleSubmit, setFieldError } = useForm({
        validationSchema: toTypedSchema(
            yup.object({
                email: yup.string()
                    .email()
                    .required()
                    .default('')
                    .label('Email'),
                password: yup.string()
                    .required()
                    .default('')
                    .label('Password'),
            }),
        ),
    });

    const [ email, emailAttrs ] = defineField('email');
    const [ password, passwordAttrs ] = defineField('password');

    const loading = ref<boolean>(false);
    const passwordSet = computed<boolean>(() => route.query['password-set'] === 'true');

    const onSubmit = handleSubmit(async values => {
        if (loading.value) {
            return;
        }

        loading.value = true;

        try {
            await auth.signIn({
                email: values.email,
                password: values.password,
            });
        } catch (err: unknown) {
            if (err instanceof SignInError) {
                console.log(err);

                // @ts-expect-error
                const error = err.errors?.nonFieldErrors?.[0].message || err.message;
                setFieldError('password', error);
            }
        }

        loading.value = false;
    }, ({ errors }) => {
        console.log(errors);
    });
</script>
